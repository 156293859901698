<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Available Training</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToTrainingList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <v-data-table :headers="headers" :items="training_list">
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.category_name }}</td>
              <td>{{ row.item.description }}</td>
              <td>
                <!--<a target="_blank" :href="back_url + row.item.path">Link</a>-->

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      small
                      v-on="on"
                      @click="openVideo(row.item.videoPath)"
                    >
                      Video
                    </v-btn>
                  </template>
                  <span>Video</span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      small
                      v-on="on"
                      @click="enrollTraining(row.item.id)"
                    >
                      Learn More
                    </v-btn>
                  </template>
                  <span>Learn More</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <!--end::Employees-->

    <!-- Feedback Dialogue -->
    <div class="text-center">
      <v-dialog v-model="video_dialogue" width="50%" style="max-height: none !important;">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Training Video
          </v-card-title>

          <v-card-text>
          <br/>
            <template>
              <Media 
                :kind="'video'"
                :controls="true"
                v-bind:src="videoPathToShow"
                style="width:100%" 
              >
              </Media>
            </template>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="video_dialogue = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Feedback Dialogue -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import Media from '@dongido/vue-viaudio';

export default {
  name: "trainingList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      training_list: [],
      video_dialogue:false,
      videoPathToShow:"",
      headers: [
        { text: "Training", value: "name", width: "25%" },
        { text: "Category", value: "category_name", width: "25%" },
        { text: "Description", value: "description", width: "25%" },
        { text: "Video", sortable: false, value: "videoPath", width: "10%" },
        { text: "Action", sortable: false, value: "action", width: "15%" }
      ],
      back_url: process.env.VUE_APP_ACBACK_URL.slice(0, -5)
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    let context = this;

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Available Training" }]);
    context.getTraining();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    enrollTraining(training_id) {
      this.$router.push({ name: "training.enroll", params: { id: training_id } });
    },
    openVideo(videoPath) {
      let context = this;
      context.videoPathToShow = videoPath;
      context.video_dialogue = true;
    },
    redirectToTrainingList() {
      this.$router.push({ name: "training.list" });
    },
    getTraining() {
      let context = this;
      axios({
        method: "get",
        url: "training",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.trainings) {
            context.training_list = result.data.trainings;
          } else {
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          Swal.fire("Error", "Training fetch error!", "error");
        }
      );
    }
  }
};
</script>
